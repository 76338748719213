import { defineStore } from "pinia";
import { db } from "@/services/db";
// firestore
import {
  collection,
  onSnapshot,
  addDoc,
  query,
  orderBy,
  setDoc,
  doc,
} from "firebase/firestore";

export const courseStore = defineStore("course", {
  state: () => ({
    courses: [],
    lessons: [],
  }),
  actions: {
    async getCourses() {
      // let that = this;
      // const querySnapshot = await getDocs(collection(db, "courses"));
      // querySnapshot.forEach((doc) => {
      //   let data = doc.data();
      //   this.courses.push(data);
      // });
      const q = query(collection(db, "courses"), orderBy("no"));
      await onSnapshot(q, (querySnapshot) => {
        this.courses = [];
        querySnapshot.forEach((doc) => {
          let data = { ...doc.data(), id: doc.id };
          this.courses.push(data);
        });
      });
    },
    async getLessons() {
      const q = query(collection(db, "lessons"), orderBy("date"));
      await onSnapshot(q, (querySnapshot) => {
        this.lessons = [];
        querySnapshot.forEach((doc) => {
          let data = { ...doc.data(), id: doc.id };
          this.lessons.push(data);
        });
      });
    },
    async addCourse({ title, desc, icon }) {
      await addDoc(collection(db, "courses"), {
        title: title,
        description: desc,
        icon: icon,
      }).catch((err) => {
        throw err;
      });
    },
    async addLesson({ title, desc, date, time, courseID }) {
      await addDoc(collection(db, "lessons"), {
        title: title,
        description: desc,
        done: false,
        room: "",
        courseID: courseID,
        time: time,
        date: date,
      }).catch((err) => {
        throw err;
      });
    },
    async editLesson(id, data) {
      await setDoc(
        doc(db, "lessons", id),
        {
          ...data,
        },
        { merge: true }
      ).catch((err) => {
        throw err;
      });
    },
  },
});

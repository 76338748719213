import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Get a Firestore instance
const app = initializeApp({
  apiKey: "AIzaSyA1f4QD9A53Y3d__O37CUsyEaQQ1ZLogSw",
  authDomain: "techify.academy",
  projectId: "techify-school",
  storageBucket: "techify-school.appspot.com",
  messagingSenderId: "89482186012",
  appId: "1:89482186012:web:274484cb1e4480eef53ed9",
  measurementId: "G-G59EWN04MX",
});

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

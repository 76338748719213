<template>
  <v-app>
    <Navigation />
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  data: () => ({
    //
  }),
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap");
#app {
  /* background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)); */
  font-family: "Quicksand", sans-serif;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
}
</style>

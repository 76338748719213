<template>
  <div>
    <v-container fluid ma-0 pa-0 class="grey lighten-2">
      <v-row no-gutters :style="isMobile ? '' : 'height: 100vh'">
        <v-col cols="12" sm="6" class="pa-3" id="header-col-left">
          <v-card flat color="transparent">
            <div class="d-flex">
              <a href="/" class="text-decoration-none">
                <h1 class="font-weight-bold primary--text">{ Techify }</h1></a
              >
              <v-spacer></v-spacer>
              <v-btn icon color="primary" v-if="isMobile" @click="openDrawer">
                <v-icon>mdi-menu</v-icon>
              </v-btn>
            </div>
          </v-card>
          <!-- heading -->

          <v-row class="fill-height" :class="isMobile ? 'mt-4' : ''">
            <v-col cols="12" :align-self="isMobile ? 'start' : 'center'">
              <v-row no-gutters>
                <v-col cols="6" sm="4">
                  <v-card tile elevation="10" color="grey lighten-2">
                    <v-card-text>
                      <p class="text-h6 font-weight-bold black--text">
                        <v-icon color="primary">mdi-head-cog</v-icon> Learn
                      </p>
                      <p class="font-weight-medium">
                        <VueTyper
                          :text="'How to write it'"
                          :repeat="0"
                          @completed="typer._1 = true"
                        />
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters justify="center">
                <v-col cols="6" sm="4">
                  <v-card tile elevation="10" color="grey lighten-2">
                    <v-card-text>
                      <p class="text-h6 font-weight-bold black--text">
                        <v-icon color="primary">mdi-code-json</v-icon> Make
                      </p>
                      <p class="font-weight-medium" v-if="typer._1">
                        <VueTyper
                          :text="'Practice with projects'"
                          :repeat="0"
                          @completed="typer._2 = true"
                        />
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters justify="end">
                <v-col cols="6" sm="4">
                  <v-card tile elevation="10" color="grey lighten-2">
                    <v-card-text>
                      <p class="text-h6 font-weight-bold black--text">
                        <v-icon color="primary">mdi-puzzle</v-icon>
                        Solve
                      </p>
                      <p class="font-weight-medium" v-if="typer._2">
                        <VueTyper :text="'Real world problems'" :repeat="0" />
                      </p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <p class="text-capitalize mt-3 font-weight-medium black--text">
                Create your account in seconds to join hundreds of other
                developers on the platform in order to sharpen your skills😊
              </p>
              <v-btn
                tile
                class="pa-3"
                color="primary"
                large
                :to="{ name: 'login' }"
                >get started <v-icon right>mdi-chevron-right</v-icon></v-btn
              >
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" v-if="!isMobile">
          <v-img
            height="100%"
            gradient="to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.8)"
            class="align-center"
            dark
            src="https://images.unsplash.com/photo-1605711285791-0219e80e43a3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=869&q=80"
          >
            <v-row justify="center">
              <v-col cols="8">
                <p class="text-subtitle-1 font-weight-medium">
                  " I quickly came to understand that code is a superpower
                  everyone should be able to access. Understanding that code is
                  the underlying (and invisible) framework of tech means that we
                  do not have to be passive bystanders in our ever-changing
                  digital world."
                </p>
                <div class="d-flex justify-end font-weight-medium">
                  ~ Karlie Kloss
                </div>
              </v-col>
            </v-row>
          </v-img>
          <v-btn
            fab
            fixed
            bottom
            color="primary"
            class="ml-n7"
            id="down-button"
            v-if="isTop"
            @click="$vuetify.goTo('#row-2')"
          >
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row
        :no-gutters="!isMobile"
        class="d-flex flex-column-reverse flex-sm-row"
        id="row-2"
      >
        <v-col cols="12" sm="6" id="row-2-col">
          <v-card width="100%" class="ma-0" tile>
            <v-img
              :height="isMobile ? '50vh' : '80vh'"
              src="https://images.unsplash.com/photo-1513258496099-48168024aec0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjd8fHN0dWRlbnQlMjBwcm9ncmFtbWluZ3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60"
            >
              <v-overlay absolute opacity="0.8">
                <v-row justify="center">
                  <v-col cols="11" sm="8">
                    <p class="text-subtitle-1 mt-2">
                      "Tech companies might be feeling the heat🔥 as rising
                      inflation and interest rates stalls growth, but demand for
                      software developers remains red hot"
                    </p>
                    <div class="d-flex justify-end">
                      <p class="text-uppercase font-weight-bold">
                        ~ Aude Barra
                      </p>
                    </div>
                    <div class="d-flex">
                      <v-card flat color="transparent" dark>
                        <v-card-text>
                          <h1 class="text-h4 text-sm-h3 text-center">676K +</h1>
                          <p
                            class="text-caption text-uppercase text-center font-weight-bold"
                          >
                            Tech Companies in the world
                          </p>
                        </v-card-text>
                      </v-card>
                      <v-card flat color="transparent" dark>
                        <v-card-text>
                          <h1 class="text-h4 text-sm-h3 text-center">100K +</h1>
                          <p
                            class="text-caption text-uppercase text-center font-weight-bold"
                          >
                            New job postings weekly
                          </p>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-col>
                </v-row>
              </v-overlay>
            </v-img>
          </v-card>
        </v-col>
        <v-col align-self="center" cols="12" sm="6" id="row-2-col">
          <div>
            <v-row justify="center">
              <v-col cols="11" sm="8">
                <h2 class="text-h4 text-capitalize text-center">
                  Brilliant Decision for your future
                </h2>
                <p class="black--text text-justify">
                  The software development industry has the fastest growth rate
                  among other business sectors.Trends are changing as fast as
                  the industry, and keeping up with them is imperative for
                  businesses and their development teams. That is why we have
                  created this platform to equip you with the required skills to
                  join the workforce😀
                </p>
                <div class="d-flex justify-end">
                  <v-btn color="primary" tile outlined :to="{ name: 'login' }"
                    >join us <v-icon right>mdi-chevron-right</v-icon></v-btn
                  >
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-row :no-gutters="!isMobile" id="row-3" class="" justify="center">
        <v-col cols="12" sm="6" align-self="center" id="row-3-col">
          <v-row justify="center">
            <v-col cols="11" sm="8">
              <h2 class="text-h4 text-capitalize text-center">
                Our learning process
              </h2>
              <p class="black--text text-justify">
                We offer an online learning environment which comes with
                advantages such as:
              </p>
              <v-list color="transparent">
                <v-list-item class="" id="benefit-item">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Flexibility ⏲</v-list-item-title>
                </v-list-item>
                <v-list-item class="" id="benefit-item">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Reduced Cost 💰</v-list-item-title>
                </v-list-item>
                <v-list-item class="" id="benefit-item">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title
                    >Personalized Learning 📚</v-list-item-title
                  >
                </v-list-item>
                <v-list-item class="" id="benefit-item">
                  <v-list-item-icon>
                    <v-icon color="primary">mdi-circle-small</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Repeated access 🎥</v-list-item-title>
                </v-list-item>
              </v-list>
              <div class="d-flex justify-end">
                <v-btn color="primary" tile outlined :to="{ name: 'login' }"
                  >join us <v-icon right>mdi-chevron-right</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="6" id="row-3-col">
          <v-img
            src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"
          >
            <v-overlay opacity="0.4" absolute>
              <v-row justify="center">
                <v-col cols="11" sm="8">
                  <h3 class="text-subtitle-1 text-center">
                    Join the diverse community of learners on the platform to
                    learn the demanded skills in the tech job market from highly
                    qualified tutors.
                  </h3>
                  <div class="d-flex justify-end mt-2">
                    <v-btn color="primary" tile :to="{ name: 'login' }"
                      >enroll <v-icon right>mdi-chevron-right</v-icon></v-btn
                    >
                  </div>
                </v-col>
              </v-row>
            </v-overlay>
          </v-img>
        </v-col>
      </v-row>
      <v-row
        :no-gutters="!isMobile"
        id="row-4"
        class="d-flex flex-column-reverse flex-sm-row"
      >
        <v-col cols="12" sm="6" id="row-4-col">
          <v-img
            src="https://images.unsplash.com/photo-1642929337692-2f900dfa0bfc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NzB8fGZydXN0cmF0ZWQlMjBwcm9ncmFtbWVyfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"
            height="400"
          >
            <v-overlay opacity="0.4" absolute>
              <v-btn color="primary" tile :to="{ name: 'login' }"
                >join the community</v-btn
              >
            </v-overlay>
          </v-img>
        </v-col>
        <v-col cols="12" sm="6" align-self="center" id="row-4-col">
          <v-row justify="center">
            <v-col cols="11" sm="8">
              <h2 class="text-h4 text-capitalize text-center">
                Developer Community
              </h2>
              <p class="black--text text-justify">
                Stuck on a bug?🤔 Ask your question on the diverse developer
                community platform Attach code snippets in your questions and
                answers. You can also help other developers with their questions
                and participate in discussions.
              </p>
              <div class="d-flex justify-end">
                <v-btn color="primary" tile outlined :to="{ name: 'login' }"
                  >join us <v-icon right>mdi-chevron-right</v-icon></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row :no-gutters="!isMobile" id="courses" class="px-4">
        <v-col>
          <h2 class="text-uppercase text-h5 mt-2 text-center">Pathways</h2>
          <v-row class="mt-2">
            <v-col cols="12" sm="4" id="course-item">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    color="grey lighten-3"
                    :elevation="hover ? '12' : '3'"
                  >
                    <v-card-title>
                      <v-img
                        src="@/assets/frontend.svg"
                        contain
                        height="150"
                      ></v-img>
                    </v-card-title>
                    <v-card-text>
                      <v-list color="transparent">
                        <v-subheader
                          class="text-subtitle-1 text-uppercase font-weight-bold"
                          >Frontend Development</v-subheader
                        >
                        <v-list-item
                          dense
                          v-for="n in [
                            'HTML',
                            'CSS',
                            'Javascript',
                            'Bootstrap',
                            'Vue Js',
                            'Vuetify',
                          ]"
                          :key="n"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-check-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ n }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <v-btn block color="primary" tile :to="{ name: 'login' }"
                        >enroll</v-btn
                      >
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="4" id="course-item">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    color="grey lighten-3"
                    :elevation="hover ? '12' : '3'"
                  >
                    <v-card-title>
                      <v-img
                        src="@/assets/backend.svg"
                        contain
                        height="150"
                      ></v-img>
                    </v-card-title>
                    <v-card-text>
                      <v-list color="transparent">
                        <v-subheader
                          class="text-subtitle-1 text-uppercase font-weight-bold"
                          >Backend Development</v-subheader
                        >
                        <v-list-item
                          dense
                          v-for="n in [
                            'Node Js',
                            'PHP',
                            'Laravel',
                            'APIs and Integration',
                            'Firebase',
                            'Common APIs e.g Mpesa, Stripe etc.',
                          ]"
                          :key="n"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-check-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ n }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <v-btn block color="primary" tile :to="{ name: 'login' }"
                        >enroll</v-btn
                      >
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
            <v-col cols="12" sm="4" id="course-item">
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    color="grey lighten-3"
                    :elevation="hover ? '12' : '3'"
                  >
                    <v-card-title>
                      <v-img
                        src="@/assets/mobile.svg"
                        contain
                        height="150"
                      ></v-img>
                    </v-card-title>
                    <v-card-text>
                      <v-list color="transparent">
                        <v-subheader
                          class="text-subtitle-1 text-uppercase font-weight-bold"
                          >Mobile Development</v-subheader
                        >
                        <v-list-item
                          dense
                          v-for="n in [
                            'Material Design',
                            'Android Studio',
                            'Dart',
                            'Flutter',
                            'Cordova',
                            'Play Store',
                          ]"
                          :key="n"
                        >
                          <v-list-item-icon>
                            <v-icon color="primary">mdi-check-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>{{ n }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                      <v-btn block color="primary" tile :to="{ name: 'login' }"
                        >enroll</v-btn
                      >
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row :no-gutters="!isMobile" id="partners" class="px-4">
        <v-col class="py-4">
          <p class="text-center text-uppercase text-h5">Our Partners</p>
          <v-row>
            <v-col
              v-for="n in companies"
              :key="n.link"
              cols="12"
              sm="3"
              align-self="center"
              id="partner"
            >
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-card
                    :elevation="hover ? '10' : '3'"
                    :href="n.link"
                    target="_blank"
                    color="transparent"
                  >
                    <v-card-text>
                      <v-img contain :src="n.src" height="100"></v-img>
                    </v-card-text>
                  </v-card>
                </template>
              </v-hover>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { VueTyper } from "vue-typer";
import { userStore } from "@/stores/user";
import { mapState, mapActions } from "pinia";
import { globalStore } from "@/stores/global";

export default {
  name: "LandingPage",
  data: () => ({
    // typer
    typer: {
      _1: false,
      _2: false,
      _3: false,
    },
    companies: [
      {
        src: "https://it.vuejs.org/images/vueschool.png",
        link: "https://vueschool.io",
      },
      {
        src: "https://download.logo.wine/logo/Netlify/Netlify-Logo.wine.png",
        link: "https://netlify.com",
      },
      {
        src: "https://it.vuejs.org/images/htmlburger.png",
        link: "https://htmlburger.com/",
      },
      {
        src: "https://it.vuejs.org/images/vuejobs.svg",
        link: "https://vuejobs.com",
      },
    ],
    isTop: true,
  }),
  computed: {
    ...mapState(userStore, ["isLoggedIn"]),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapActions(globalStore, ["openDrawer"]),
  },
  components: {
    // LottieAnimation,
    VueTyper,
  },
  created() {
    // toggle the down button
    window.addEventListener("scroll", () => {
      if (window.scrollY > 13) this.isTop = false;
      else this.isTop = true;
    });
  },
  mounted() {
    // bring in row 2
    this.gsap.fromTo(
      "#row-2-col",
      {
        x: 200,
      },
      {
        x: 0,
        duration: 3,
        scrollTrigger: {
          trigger: "#row-2",
          start: "top bottom",
          // scrub: true,
        },
      }
    );
    // bring row 3 in
    let row3tl = this.gsap.timeline({
      scrollTrigger: {
        trigger: "#row-3",
        start: "top bottom",
        // scrub: true,
      },
    });
    row3tl.fromTo(
      "#row-3-col",
      {
        x: -200,
      },
      {
        x: 0,
        duration: 3,
      }
    );
    // benefit items
    this.gsap.fromTo(
      "#benefit-item",
      { x: -30, scale: 0 },
      {
        x: 0,
        scale: 1,
        duration: 0.2,
        stagger: 0.4,
        scrollTrigger: {
          trigger: "#row-3",
          start: "top center",
        },
      },
      ">-2"
    );
    // bring row 3 in
    let row4tl = this.gsap.timeline({
      scrollTrigger: {
        trigger: "#row-4",
        start: "top bottom",
        // scrub: true,
      },
    });
    row4tl.fromTo(
      "#row-4-col",
      {
        x: 200,
      },
      {
        x: 0,
        duration: 3,
      }
    );
    // bring in the courses
    this.gsap.fromTo(
      "#course-item",
      { x: -200 },
      {
        x: 0,
        duration: 2,
        stagger: 0.5,
        scrollTrigger: {
          trigger: "#courses",
        },
      }
    );
    // pop up the partners
    this.gsap.fromTo(
      "#partner",
      {
        scale: 0,
      },
      {
        scale: 1,
        stagger: 0.5,
        duration: 1,
        scrollTrigger: {
          trigger: "#partners",
        },
        ease: "elastic",
      }
    );
  },
};
</script>

<style scoped>
/* .container {
  height: 100vh;
  width: 100vw;
} */
#header-col-left {
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
    url("https://img.freepik.com/free-vector/white-background-with-blue-tech-hexagon_1017-19366.jpg?w=740&t=st=1669374577~exp=1669375177~hmac=a1551dc75cc98a922eabef558dedebb455bd9770105e77f21acd9cb42b985648");
}

/* .vue-typer {
  white-space: pre;
} */
</style>

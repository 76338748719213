import Vue from "vue";
import VueRouter from "vue-router";
import Landing from "../views/Landing.vue";

// pinia
import { userStore } from "@/stores/user";

// nprogress
import Nprogess from "nprogress";
import "nprogress/nprogress.css";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/about-us",
    name: "about",
    component: () => import("../views/About"),
  },
  {
    path: "/contact-us",
    name: "contact",
    component: () => import("../views/Contact"),
  },
  {
    path: "/auth",
    name: "login",
    component: () => import("../views/Login"),
  },
  {
    path: "/dashboard",
    // name: "dashboard",
    component: () => import("../views/Dashboard"),
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import("../views/UserViews/Dashboard"),
      },
      {
        path: "courses",
        component: () => import("../views/UserViews/Courses.vue"),
        children: [
          {
            path: "",
            name: "courses",
            component: () => import("../views/UserViews/Courses/CoursesView"),
          },
          {
            path: "lessons/:id",
            name: "lessons",
            component: () => import("../views/UserViews/Courses/LessonView"),
          },
        ],
      },
      {
        path: "profile",
        name: "profile",
        component: () => import("../views/UserViews/Profile"),
      },
      {
        path: "payment",
        name: "payment",
        component: () => import("../views/UserViews/Payment"),
      },
      {
        path: "payment-success",
        name: "payment-success",
        component: () => import("../views/UserViews/PaymentSuccess"),
      },
      {
        path: "quizes",
        component: () => import("../views/UserViews/Quizes.vue"),
        children: [
          {
            path: "",
            name: "quiz",
            component: () => import("../views/UserViews/Quizes/AllQuizes"),
          },
          {
            path: "daily-quiz/:id",
            name: "do-quiz",
            component: () => import("../views/UserViews/Quizes/QuizView"),
          },
        ],
      },
      {
        path: "community",
        name: "community",
        component: () => import("../views/UserViews/Community.vue"),
      },
      // ADMIN
      {
        path: "start-lesson/:id",
        name: "start-lesson",
        component: () => import("../views/Admin/StartLesson"),
      },
      {
        path: "create-lesson",
        name: "create-lesson",
        component: () => import("../views/Admin/CreateLesson"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.name == "login") return { top: 0 };
  },
});

router.beforeEach((to, _, next) => {
  Nprogess.start();
  // get login status
  const isLoggedIn = userStore().isLoggedIn;
  // check if navigating to auth required pages
  let authPages =
    to.name !== "landing" && to.name !== "login" && to.name !== "contact";
  // filter auth-required pages
  if (authPages && !isLoggedIn) next({ name: "landing" });
  // filter non-auth pages
  else if (!authPages && isLoggedIn) next({ name: "dashboard" });
  else next();
});

router.afterEach(() => {
  Nprogess.done();
});

export default router;

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";

// imports
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { createPinia, PiniaVuePlugin } from "pinia";
import { createPersistedStatePlugin } from "pinia-plugin-persistedstate-2";
import moment from "vue-moment";

// firebase
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./services/db";
import { userStore } from "./stores/user";
import { courseStore } from "./stores/courses";
import { firestorePlugin } from "vuefire";

// flutterwave
import Flutterwave from "flutterwave-vue-v3";

gsap.registerPlugin(ScrollTrigger);
ScrollTrigger.defaults({
  toggleActions: "restart pause resume none",
});

Vue.config.productionTip = false;
Vue.mixin({
  created() {
    this.gsap = gsap;
  },
});
Vue.use(PiniaVuePlugin);
Vue.use(moment);
Vue.use(firestorePlugin);
Vue.use(Flutterwave, {
  publicKey: "FLWPUBK-dfaf04288079e0a56f1a38d36f0f5f0c-X",
});

// export to use in router
export const pinia = createPinia();
const persistedstate = createPersistedStatePlugin();
pinia.use(persistedstate);
const storeUser = userStore(pinia);
const storeCourse = courseStore(pinia);

new Vue({
  router,
  vuetify,
  pinia,
  created() {
    // get the state of auth
    onAuthStateChanged(auth, (user) => {
      storeUser.getUser(user);
      if (user) {
        // redirect user
        if (this.$route.name != "dashboard")
          this.$router.replace({ name: "dashboard" });
        // subscribe to snapshots for courses
        storeCourse.getCourses();
        storeCourse.getLessons();
      }
    });
  },
  render: (h) => h(App),
}).$mount("#app");

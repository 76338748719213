<template>
  <v-footer app absolute inset class="grey lighten-2">
    <p class="mx-sm-auto text-capitalize">
      All rights reserved. Techify &copy; {{ new Date() | moment("YYYY") }}.
    </p>
    <v-speed-dial
      v-model="fab"
      bottom
      right
      absolute
      open-on-hover
      transition="slide-y-reverse-transition"
      direction="top"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab small>
          <v-icon v-if="fab"> mdi-close </v-icon>
          <v-icon v-else> mdi-phone </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        small
        href="https://www.instagram.com/Techify_Academy/"
        target="_blank"
      >
        <v-icon color="red lighten-1">mdi-instagram</v-icon>
      </v-btn>
      <v-btn
        fab
        small
        href="https://www.linkedin.com/company/techify-institute/"
        target="_blank"
      >
        <v-icon color="blue darken-2">mdi-linkedin</v-icon>
      </v-btn>
      <v-btn
        fab
        small
        href="https://twitter.com/TechifyAcademy"
        target="_blank"
      >
        <v-icon color="blue">mdi-twitter</v-icon>
      </v-btn>
    </v-speed-dial>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => ({
    fab: false,
  }),
};
</script>

<style></style>

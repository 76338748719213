<template>
  <div>
    <v-app-bar
      v-if="!isLoggedIn"
      app
      inverted-scroll
      color="grey lighten-2"
      clipped-left
    >
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click="mobileDrawer = !mobileDrawer"
      ></v-app-bar-nav-icon>
      <a href="/" class="text-decoration-none">
        <h1
          class="my-auto primary--text text-h4 font-weight-bold"
          v-if="!isMobile"
        >
          { Techify }
        </h1>
      </a>
      <v-spacer></v-spacer>
      <div v-if="!isMobile">
        <v-btn
          color="black"
          to="/"
          exact
          plain
          active-class="primary--text"
          class="mr-2"
          >home</v-btn
        >
        <!-- <v-btn
          plain
          to="/about-us"
          exact
          active-class="primary--text"
          color="black"
          class="mr-2"
          >about us</v-btn
        > -->
        <v-btn
          plain
          to="contact-us"
          exact
          active-class="primary--text"
          color="black"
          class="mr-2"
          >contact us</v-btn
        >
      </div>
      <v-btn
        color="primary"
        class="pa-3"
        outlined
        text
        :to="{ name: 'login' }"
        tile
        >login</v-btn
      >
      <v-btn color="primary" class="pa-3 ml-3" :to="{ name: 'login' }" tile
        >sign up</v-btn
      >
    </v-app-bar>

    <!-- mobile drawer -->
    <v-navigation-drawer
      app
      v-model="mobileDrawer"
      color="#f7f7f7"
      v-if="isMobile"
    >
      <v-card color="transparent" flat>
        <!-- show list if not logged in i.e non-auth pages -->
        <v-list nav dense v-if="!isLoggedIn">
          <div class="d-flex justify-center">
            <v-subheader class="text-h5 primary--text font-weight-bold">
              { Techify }
            </v-subheader>
          </div>
          <v-divider class="my-2"></v-divider>
          <v-list-item link active-class="primary--text" to="/" exact>
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>
          <v-list-item link active-class="primary--text" to="/about-us" exact>
            <v-list-item-icon>
              <v-icon>mdi-domain</v-icon>
            </v-list-item-icon>
            <v-list-item-title>About Us</v-list-item-title>
          </v-list-item>
          <v-list-item link active-class="primary--text" to="/contact-us" exact>
            <v-list-item-icon>
              <v-icon>mdi-phone</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Contact Us</v-list-item-title>
          </v-list-item>
        </v-list>
        <!-- show list if logged in i.e auth pages -->
        <v-list nav shaped dense v-if="isLoggedIn">
          <div class="d-flex justify-center">
            <v-subheader class="text-h5 primary--text font-weight-bold">
              { Techify }
            </v-subheader>
          </div>
          <v-divider class="mt-2"></v-divider>
          <v-list-item to="/dashboard" active-class="primary--text" exact>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'courses' }" active-class="primary--text">
            <v-list-item-icon>
              <v-icon>mdi-book</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Courses</v-list-item-title>
          </v-list-item>
          <!-- <v-list-item :to="{ name: 'quiz' }" active-class="primary--text">
            <v-list-item-icon>
              <v-icon>mdi-brain</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Quizes</v-list-item-title>
          </v-list-item> -->
          <v-list-item :to="{ name: 'community' }" active-class="primary--text">
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Community</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'profile' }" active-class="primary--text">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profile</v-list-item-title>
          </v-list-item>
          <v-list-item :to="{ name: 'payment' }" active-class="primary--text">
            <v-list-item-icon>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Subscriptions</v-list-item-title>
          </v-list-item>
          <v-subheader
            class="text-uppercase font-weight-bold"
            v-if="user && user.role == 'admin'"
            >admin</v-subheader
          >
          <v-list-item
            :to="{ name: 'create-lesson' }"
            active-class="primary--text"
            v-if="user && user.role == 'admin'"
          >
            <v-list-item-icon>
              <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Create Lesson</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-card>
      <template v-slot:append>
        <div class="mx-3">
          <v-btn
            v-if="isLoggedIn"
            color="primary"
            block
            tile
            @click="logoutUser"
            >logout</v-btn
          >
        </div>
        <v-card color="grey lighten-2" class="ma-3">
          <v-card-text>
            <v-img src="@/assets/pair_programming.svg"></v-img>
            <p class="text-center">We'll make you the best that you can be</p>
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>

    <!-- navigation drawer for desktop -->
    <v-navigation-drawer
      v-if="!isMobile && isLoggedIn"
      app
      clipped
      color="grey lighten-4"
      permanent
    >
      <v-subheader class="text-uppercase font-weight-bold"
        >navigation</v-subheader
      >
      <v-list nav shaped dense>
        <v-list-item to="/dashboard" active-class="primary--text" exact>
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'courses' }" active-class="primary--text">
          <v-list-item-icon>
            <v-icon>mdi-book</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Courses</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item :to="{ name: 'quiz' }" active-class="primary--text">
          <v-list-item-icon>
            <v-icon>mdi-brain</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Quiz</v-list-item-title>
        </v-list-item> -->
        <v-list-item :to="{ name: 'community' }" active-class="primary--text">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Community</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'profile' }" active-class="primary--text">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Profile</v-list-item-title>
        </v-list-item>
        <v-list-item :to="{ name: 'payment' }" active-class="primary--text">
          <v-list-item-icon>
            <v-icon>mdi-account-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Subscription</v-list-item-title>
        </v-list-item>
        <v-subheader
          class="text-uppercase font-weight-bold"
          v-if="user && user.role == 'admin'"
          >admin</v-subheader
        >
        <v-list-item
          :to="{ name: 'create-lesson' }"
          active-class="primary--text"
          v-if="user && user.role == 'admin'"
        >
          <v-list-item-icon>
            <v-icon>mdi-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Create Lesson</v-list-item-title>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <div class="mx-3">
          <v-btn color="primary" block tile @click="logout">logout</v-btn>
        </div>
        <v-card color="grey lighten-2" class="ma-3">
          <v-card-text>
            <v-img src="../assets/pair_programming.svg"></v-img>
            <p class="text-center">We'll make you the best that you can be</p>
          </v-card-text>
        </v-card>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapWritableState, mapActions } from "pinia";
import { userStore } from "../stores/user";
import { globalStore } from "../stores/global";

export default {
  name: "NavigationView",
  data: () => ({
    isTop: true,
  }),
  computed: {
    ...mapState(userStore, ["isLoggedIn", "user"]),
    ...mapWritableState(globalStore, {
      mobileDrawer: "drawer",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapActions(userStore, ["logoutUser"]),
    logout() {
      this.logoutUser().then(() => {
        this.$router.replace({ name: "landing" });
      });
    },
  },
  created() {
    // toggle app bar
    window.addEventListener("scroll", () => {
      if (window.scrollY > 13) this.isTop = false;
      else this.isTop = true;
    });
  },
};
</script>

<style></style>

import { defineStore } from "pinia";

export const globalStore = defineStore("global", {
  state: () => ({
    drawer: false,
    loginDialog: false,
  }),
  actions: {
    openDrawer() {
      this.drawer = !this.drawer;
    },
  },
});

import { defineStore } from "pinia";
import { auth, db, storage } from "@/services/db";
// authentication
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updatePassword,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
} from "firebase/auth";
// firestore
import { setDoc, doc, onSnapshot } from "firebase/firestore";
// storage
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";

export const userStore = defineStore("user", {
  state: () => ({
    user: {},
    isLoggedIn: false,
    userProfileImage: "",
  }),
  actions: {
    createUser({ name, email, phone, password }) {
      return createUserWithEmailAndPassword(auth, email, password)
        .then((UserCredential) => {
          // set token
          localStorage.setItem("token", UserCredential.user.uid);
          this.isLoggedIn = true;
          // add user's name and phone
          updateProfile(auth.currentUser, {
            displayName: name,
          });
          // save other user details in firestore
          this.saveUser(phone, UserCredential.user.uid).then(() => {
            this.getDBUser(UserCredential.user);
          });
        })
        .catch((err) => {
          throw err;
        });
    },
    async saveUser(phone, id) {
      await setDoc(doc(db, "users", id), {
        phone: phone,
        bio: "",
        role: "user",
        subscription: [],
        emailNotifications: true,
      }).catch((err) => {
        throw err;
      });
    },
    loginUser(email, password) {
      return signInWithEmailAndPassword(auth, email, password)
        .then((UserCredential) => {
          this.getDBUser(UserCredential.user);
        })
        .catch((err) => {
          throw err;
        });
    },
    async logoutUser() {
      await signOut(auth);
      this.isLoggedIn = false;
      localStorage.removeItem("token");
    },
    getUser(user) {
      if (user) {
        this.isLoggedIn = true;
        this.getDBUser(user);
      } else {
        this.isLoggedIn = false;
      }
    },
    getUserpProfileImage() {
      if (this.user.photoURL) this.userProfileImage = this.user.photoURL;
      else
        this.userProfileImage =
          "https://images.unsplash.com/photo-1516259762381-22954d7d3ad2?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Y29kZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60F";
    },
    async getDBUser(user) {
      onSnapshot(doc(db, "users", user.uid), (doc) => {
        this.user = { ...user, ...doc.data() };
        this.getUserpProfileImage();
      });
    },
    async updateUserSubscriptionStatus(subscription) {
      await setDoc(
        doc(db, "users", this.user.uid),
        {
          subscription: subscription,
        },
        { merge: true }
      ).then(() => {});
    },
    async editProfile(name, bio, notifications) {
      // update auth profile
      return await updateProfile(auth.currentUser, {
        displayName: name,
      }).then(async () => {
        // update firestore user data
        await setDoc(
          doc(db, "users", this.user.uid),
          {
            bio: bio,
            emailNotifications: notifications,
          },
          { merge: true }
        ).catch((err) => {
          throw err;
        });
      });
    },
    updateUserPassword(newPass) {
      reauthenticateWithCredential();
      return updatePassword(auth.currentUser, newPass).catch((err) => {
        throw err;
      });
    },
    updateUserProfileImage(file) {
      const reference = ref(storage, `profile-image/${this.user.uid}`);
      // upload
      return uploadBytes(reference, file).then((snapshot) => {
        // save the url to profile
        getDownloadURL(snapshot.ref).then((url) => {
          this.userProfileImage = url;
          updateProfile(auth.currentUser, {
            photoURL: url,
          });
        });
      });
    },
    resetPassword(email) {
      return sendPasswordResetEmail(auth, email).catch((err) => {
        throw err;
      });
    },
  },
});
